import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { createContext } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

import { getLocale } from '@common/utils/locale';

import { googleApiKey } from '../../../../config/config';

const GoogleMapsContext = createContext<{ isLoaded: boolean }>({
  isLoaded: false,
});

export function GoogleMapsProvider({ children }: { children: ReactNode }) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (googleApiKey) {
      const loader = new Loader({
        apiKey: googleApiKey ?? '',
        // TODO: make this dynamic https://github.com/googlemaps/js-api-loader/issues/210
        language: getLocale() ?? undefined,
        libraries: ['places'],
        version: 'weekly',
      });

      loader.load().then(
        () => setIsLoaded(true),
        () => setIsLoaded(false),
      );
    }
  }, []);

  return (
    <GoogleMapsContext.Provider value={{ isLoaded }}>
      {children}
    </GoogleMapsContext.Provider>
  );
}

export function useGoogleLoader() {
  return useContext(GoogleMapsContext);
}
